import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"

const LandingPage = () => {
    console.log("Landing?")
   return (
   <Layout>
        <h1>LandingPage</h1>
   </Layout>
   )
} 

export default LandingPage
